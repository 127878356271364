<template>
  <div>
    <div
      class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      v-if="loading"
    >
      <t-loader extraClass="border-primary-900 w-12 h-12" />
    </div>
    <div v-if="!loading" class="pt-2">
      <div
        class="w-full grid grid-cols-1 xs:grid-cols-2 md:grid-cols-4 gap-2 gap-y-4 mb-4"
      >
        <div style="height: 130px" class="mx-1">
          <stat-card
            :loading="loading"
            :statistic="{
              title: 'Torneos',
              quantity: data.counters.tournaments.total,
              icon: 'trophy',
              change: data.counters.tournaments.actives,
              changeVar: 'torneos en curso'
            }"
            bgColor="bg-yellow-1100"
          />
        </div>

        <div style="height: 130px" class="mx-1">
          <stat-card
            :loading="loading"
            :statistic="{
              title: 'Academias',
              quantity: data.counters.academies.total,
              icon: 'shield-alt',
              change: data.counters.academies.actives,
              changeVar: 'equipos activos'
            }"
            bgColor="bg-red-1100"
          />
        </div>

        <div style="height: 130px" class="mx-1">
          <stat-card
            :loading="loading"
            :statistic="{
              title: 'Jugadores',
              quantity: data.counters.players.total,
              icon: 'users',
              change: data.counters.players.actives,
              changeVar: 'jugadores activos'
            }"
            bgColor="bg-green-1100"
          />
        </div>
        <div style="height: 130px" class="mx-1">
          <stat-card
            :loading="loading"
            :statistic="{
              title: 'Partidos',
              quantity: data.counters.games.total,
              icon: 'volleyball-ball',
              change: data.counters.games.actives,
              changeVar: 'partidos activos'
            }"
            bgColor="bg-orange-1100"
          />
        </div>
      </div>

      <div class="flex flex-col md:flex-row align-center justify-between my-4">
        <div class="flex flex-col md:flex-row align-center">
          <div class="my-auto mx-2">
            <searchbar
              placeholder="Buscar"
              v-model="query"
              @changeQuery="changeQuery"
            ></searchbar>
          </div>
          <t-input-group class="mx-2">
            <t-select
              v-model="parameters.active"
              placeholder="Estatus"
              :options="[{ id: 1, name: 'Activos' },{ id: 0, name: 'Inactivos' }]"
              textAttribute="name"
              valueAttribute="id"
            ></t-select>
          </t-input-group>
          <t-input-group class="mx-2">
            <t-select
              v-model="parameters.status"
              placeholder="Razón"
              :options="[{ id: 1, name: 'Por iniciar' },{ id: 2, name: 'En curso' },{ id: 3, name: 'Finalizado' }]"
              textAttribute="name"
              valueAttribute="id"
            ></t-select>
          </t-input-group>
          <t-input-group class="mx-2">
            <t-select
              v-model="parameters.id_tournament_region"
              placeholder="Selecciona una región"
              :options="regions"
              textAttribute="name"
              valueAttribute="id"
            ></t-select>
          </t-input-group>
        </div>

        <t-button
          class="my-auto mr-2 h-fit py-2"
          @click="$router.push('/torneos/crear')"
        >
          Crear torneo
        </t-button>
      </div>

      <div class="w-full">
        <basic-table
          ref="list-table"
          module="tournament"
          :headers="headers"
          :url="'torneos'"
          :data="tournaments"
          :route="'/tournament'"
          :tableName="'TORNEOS'"
          :parameters="parameters"
          :hasDetail="false"
          :hasDetail3="true"
          @deactivate="deactivate"
          @activate="activate"
          @erase="erase"
        />
      </div>
    </div>
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import StatCard from "@/components/Statistics/Card.vue"
import Searchbar from "@/components/Searchbar"
export default {
  components: {
    StatCard,
    Searchbar
  },
  data() {
    return {
      loading: true,
      submitLoader: false,
      headers: [
        "",
        "Nombre",
        "Categorías",
        "Equipos",
        "Partidos",
        "Región",
        "Inicio",
        "Fin",
        "Acciones"
      ],
      data: {
        counters: {
          tournaments: {},
          academies: {},
          players: {},
          coaches: {},
          notices: {},
          games: {},
          categories: {},
          titles: {}
        }
      },
      query: "",
      parameters: {
        active: 1,
        order_key: 'date_from',
        order_value: 'desc',
        status: 2,
        id_tournament_region: null
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters["auth/getUser"]
    },
    regions() {
      return this.$store.getters["global/getItems"](
        "tournamentRegion",
        "unpaginated_elements"
      )
    },
    tournaments() {
      return this.$store.getters["global/getItems"](
        "tournament",
        "elements"
      ).map((el) => ({
        id: el.id,
        img_logo: el.url_logo,
        name: el.name,
        categories: el.categories,
        teams: el.teams,
        games: el.games.played + " / " + el.games.total,
        region: el.tournament_region
          ? el.tournament_region.name
          : "No especificada",
        date_from: el.date_from,
        date_to: el.date_to,
        active: el.active
      }))
    }
  },
  methods: {
    changeQuery(value) {
      if (this.$refs["list-table"]) this.$refs["list-table"].changeQuery(value)
    },
    getCounters() {
      this.$store
        .dispatch("global/getItems", {
          route: "user/admin/counters",
          noPaginate: true
        })
        .then((response) => {
          this.data = response.data
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    getRegions() {
      this.$store
        .dispatch("global/getItems", {
          module: "tournamentRegion",
          route: "/tournament_region/all",
          noPaginate: true,
          isMaster: true,
          params: {
            active: 1,
            order_key: "name",
            order_value: "asc"
          }
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    deactivate() {
      this.data.counters.tournaments.actives--
    },
    activate() {
      this.data.counters.tournaments.actives++
    },
    erase() {
      this.data.counters.tournaments.total--
      this.data.counters.tournaments.actives--
    }
  },
  created() {
    this.getCounters()
    this.getRegions()
  }
}
</script>
